$red: #f00;
$white: #fff;
$black: #000;
$whiteOpaque: #ffffffcc;
$greyOpaque: #f6f6f6cc;
$gray: #808080;
$charcoal: #282c36;
$proTextGray: #555;
$proTextGrayAlt: #4a4a4a;
$proGreyLight: #f3f6fb;
$proGreyLightAlt: #959ea7;
$proGreyLightAltPillar: #f8f8f8;
$proGreyLightBlock: #eaeef3;
$proGreyPillarBorder: #e8e8e8;
$proBluePillarBorder: #eef1f6;
$proDarkGrey: #707070;
$proDarkBlue: #2c3e50;
$proBlue: #7ac3d3;
$proBlueAlt: #2c3e50;
$proBlueLight: #e1f0f5;
$proBlueLightAlt: #fafdfe; /* For extra accessibilty on homepage news slider */
$lightBlue: #81dcf7; /* For extra accessibilty on dark backgrounds */
$lightBlueAlt: #e1f0f5;
$lightBlueRgb: '129, 220, 247';
$proCategoryBlue: #d9ecf1;
$proCategoryBlueDark: #b5dce5;
$proSeriesGray: #fcfcfe;
$proLilac: #f3f6fa;
$proLilacHover: #e1f0f5;
$proTeal: #76c3d4;
$proTableLightBlue: #f0f8f9;
$proTableLightBlueHover: #e2f2f5;
$proTableDarkBlue: #394b5b;
$proDownloadBlue: #f0f9fb;
$proDownloadButtonBlue: #7ac3d3;
$proDownloadButtonBlueHover: #7ab3d3;
$proTextAccent: #406171;
$proTextAccentLight: #e1e3e5;
$blue: #009fe3; /* For extra accessibilty on light backgrounds */
$brightBlue: #1b79a7; /* Accessibility out the wazoo, innit? */
$ctaLight: #f1f9fb; /* For extra accessibilty on CTA buttons only */
$heroCTAYellow: #ffd200; /* For extra accessibilty on CTA buttons only */
$errorRed: #db4e3c;
$successGreen: #3cb878;
$warningYellow: #fbbb10;
$borderGrey: #898989;
$borderBlue: #009edb;
$white: #fff;
$altBgGrey: #f4f7fa;
$gold: #e8c271;
$teal: #67bac8;
$yetAnotherFuckingLightBlueWhatEvenAreDesignStandardsAnyway: #f0fafc;
$solBlue: #0a5e9d;
$solutionsDark: #141414;
$solutionsDarkButtonBlue: #1994f0;

$titleTeal: #52b5d5;
$envelopeTeal: #64c3de;
$titleBlue: #3591ca;
$envelopeBlue: #44a3d5;
$titleDarkBlue: #1a3e7b;
$envelopeDarkBlue: #234e8e;

$aqua: #00fcf0;

/* typography variables */
$breadCrumbBackgroundColor: #f6f6f6;
$breadCrumbTextColor: #555;
$breadCrumbDividerBackgroundColor: #5a5b5d;
$breadCrumbDividerBackgroundColorAlt: #dedbdb;

$panelBorderGrey: #bebebe;

/* navigation variables */
$toolTipBgColor: #35495e;

/* typography variables */
$fontStack: 'SST W20 Light', Verdana, sans-serif;
$romanFontStack: 'SST W20 Roman', Verdana, sans-serif;
$headerFontStack: 'SST W20 Light', Verdana, sans-serif;
$headerBoldFontStack: 'SST W20 Medium', Verdana, sans-serif;
$buttonFontStack: 'SST W20 Medium', Verdana, sans-serif;
$iconFontStack: 'sonyngp' !important;
$quoteFontStack: Georgia, 'Times New Roman', Times, serif;

$fontStackCN: 'Microsoft YaHei', sans-serif;
$romanFontStackCN: 'Microsoft YaHei', sans-serif;
$headerFontStackCN: 'Microsoft YaHei', sans-serif;
$headerBoldFontStackCN: 'Microsoft YaHei', sans-serif;
$buttonFontStackCN: 'Microsoft YaHei', sans-serif;

$bodyColor: $charcoal;
$headersColor: #2c3e50;

$bodySize: 16px;
$bodyLineHeight: 24px;

$bodyReducedSize: 14px;
$bodyReducedLineHeight: 18px;

$bodyIncreasedSize: 18px;
$bodyIncreasedLineHeight: 28px;

$headerIncreasedSize: 42px;
$headerIncreasedLineHeight: 58px;
$headerReducedWeight: 500;

$h1Size: 32px;
$h1LineHeight: 42px;

$h2Size: 28px;
$h2LineHeight: 38px;

$h3Size: 24px;
$h3LineHeight: 34px;

$h4Size: 20px;
$h4LineHeight: 30px;

$icon2x: 28px;
$icon3x: 42px;
$icon4x: 56px;
$icon5x: 70px;
$icon6x: 84px;
$icon7x: 98px;

$subtitleIncreasedSize: 20px;
$subtitleIncreasedLineHeight: 30px;

$buttonSize: 16px;
$buttonSizeBig: 18px;
$buttonLineHeight: 21px;
$buttonLineHeightBig: 23px;

$navigationCloseButtonSize: 40px;

$linkColor: $solBlue;
$linkColorState: $solBlue;

$linkColorOnDark: #4572ed;

$proAccessibleGrey: #3c4c74;
$proVeryDarkBlue: #35495e;
$aqua: #00fcf0;

$primeSupportRed: #a91f26;

$proCardGrey: #ebebeb;
/* solutions area */
$solBlue: #0a5e9d;
$solBorderGrey: #e4e4e4;
$assetHighlightsGrey: #d9d9d9cc;

$panelBorderGrey: #bebebe;

/* navigation variables */
$toolTipBgColor: #35495e;

// $red: #f00; // Used in GenericPillar as a text color and _icons as a util which isn't called anywhere
// $white: #fff; // Used extensively as a background color and text color , should probably set as a secondary text color
// $whiteOpaque: #ffffffcc; // Only used in AssetHighlights, should probably settle as a secondary text color and add opacity with function
// $gray: #808080; // Only use in GeoLocation module and a background color
// $charcoal: #282c36; // Text Color set as text-primary-base
// $proGreyLightAlt: #959ea7; // Used a lot, primarily as a background or border colour
// $proGreyLightAltPillar: #f8f8f8; // Used in DynamicForm and GenericPillar
// $proGreyLightBlock: #eaeef3; // Only used in SwtCotentSummaryBlock
// $proGreyPillarBorder: #e8e8e8; // Only used in GenericPillar
// $proBluePillarBorder: #eef1f6; // Only used in GenericPillar
// $proDarkBlue: #2c3e50; // This is a text colour
// $proBlueLightAlt: #fafdfe; /* For extra accessibilty on homepage news slider */
// $lightBlue: #81dcf7; /* For extra accessibilty on dark backgrounds */
// $lightBlueAlt: #e1f0f5; // Used in Homepage Hero, RelatedAssets, RelatedProductAccessories as a border colour, text colour, and background colour
// $proCategoryBlue: #d9ecf1; // Used in product category as background color and border color, used in SwtContentSummaryToC as a background
// $proCategoryBlueDark: #b5dce5; // Used in Product Category and SwtContentSummaryToC as a border color and background colour
// $proSeriesGray: #fcfcfe; // Used in ProductCategory and ProductSeries as a background color
// $proTableLightBlue: #f0f8f9; // Used in StandardTable as a background colour and maybe the DatePicker?
// $proTableLightBlueHover: #e2f2f5; // Used in StandardTable
// $proTableDarkBlue: #394b5b; // Used in StandardTable as a background color and text color, also maybe  used in DatePicker
// $proDownloadBlue: #f0f9fb; // Used Only in PartnerAssetView
// $proDownloadButtonBlue: #7ac3d3; // Used in _buttons.scss, which is called in PartnerAssetView
// $proDownloadButtonBlueHover: #7ab3d3; // Used in _buttons.scss, which is called in PartnerAssetView
// $proTextAccent: #406171; // Used in _themes? But i not sure it's actually used
// $proTextAccentLight: #e1e3e5; // Used in _themes? But i not sure it's actually used
// $blue: #009fe3; /* For extra accessibilty on light backgrounds */ // Only used in HomepageHero as a border colour and breadcrumb as a text colour
// $brightBlue: #1b79a7; /* Accessibility out the wazoo, innit? */ // Only used in the SitMap module
// $ctaLight: #f1f9fb; /* For extra accessibilty on CTA buttons only */
// $heroCTAYellow: #ffd200; /* For extra accessibilty on CTA buttons only */
// $borderGrey: #898989; // Only used in partner asset list
// $borderBlue: #009edb; // Not Used
// $teal: #67bac8; // Only used in GenericPillar and PSProductDetails module
// $yetAnotherFuckingLightBlueWhatEvenAreDesignStandardsAnyway: #f0fafc; // Only used in ContentCard module
// $solBlue: #0a5e9d; // Used extensively primarily as a text color, but also in backgrounds and borders
// $solutionsDark: #141414; // Only used in dark NavigationHub
// $solutionsDarkButtonBlue: #1994f0; // Only used in dark NavigationHub

// $titleTeal: #52b5d5; // Only used in GenericPillar module
// $envelopeTeal: #64c3de; // Only used in GenericPillar module
// $titleBlue: #3591ca; // Only used in GenericPillar module
// $envelopeBlue: #44a3d5; // Only used in GenericPillar module
// $titleDarkBlue: #1a3e7b; // Only used in GenericPillar module
// $envelopeDarkBlue: #234e8e; // Only used in GenericPillar module

// $aqua: #00fcf0; // Only used in PromotionalBanner module

// /* typography variables */
// Do we need specific variables for the breadcrumb module?
// $breadCrumbBackgroundColor: #f6f6f6; // Used in a number of modules as a background color or border color AssetEvents, BlockLinks, Breadcrumb, ContentCard, NavigationHub, Post(Event), and Modal
// $breadCrumbTextColor: #555; // Only used in breadcrump as a text color
// $breadCrumbDividerBackgroundColor: #5a5b5d; // Only used in breadcrump as a background colour
// $breadCrumbDividerBackgroundColorAlt: #dedbdb; // Only used in breadcrump as a background colour

// $panelBorderGrey: #bebebe; // Used in SwtContentView and ProductSpecificationBlock as background color

// $proAccessibleGrey: #3c4c74; // Only used in NewsLinkBlocks
// $proVeryDarkBlue: #35495e; // Used in a couple of modules as a text colour AttachmentDownload NewsLinkBlocks ProductResourcesBlock StickyNav SwtSearchResults
// $aqua: #00fcf0; // Only used in PromotionalBanner

// $primeSupportRed: #a91f26; // Only used in GenericPillar... could probably be swapped for a global red warning colour

// /* solutions area */
// $solBlue: #0a5e9d; // Used in a lot of modules primarily as a text colour, but also in backgrounds and borders
// $solBorderGrey: #e4e4e4; // Only used in NavigationHub as a border colour
// $assetHighlightsGrey: #d9d9d9cc; // Used in BlockLinks ContentCard Post(Events) as background colour

$colors: (
    background: (
        primary-base: #fff,
        primary-dark: #000,
        primary-light: #f4f7fa,
        secondary-base: #6c757d,
        secondary-dark: #494f54,
        secondary-light: #a0a5aa,
        tertiary-base: #f3f6fa,
        tertiary-dark: #e1f0f5,
        tertiary-light: #f3f6fb,
        tool-tip-bg-Color: #35495e,
    ),
    gradients: (
        primary:
            linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.81) 61%,
                rgba(255, 255, 255, 1) 100%
            ),
        secondary: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0) 75%),
    ),
    text: (
        primary-base: #282c36,
        primary-dark: #121416,
        primary-light: #495057,
        secondary-base: #555,
        secondary-dark: #4a4a4a,
        secondary-light: #f3f6fb,
        header-primary-base: #2c3e50,
        header-primary-dark: #1a3e7b,
        header-primary-light: #234e8e,
        link-primary-base: #0a5e9d,
        link-primary-dark: #4572ed,
        link-primary-light: #1994f0,
    ),
    infomation: (
        success: #3cb878,
        warning: #fbbb10,
        danger: #db4e3c,
    ),
    boder: (
        border-primary-base: #7ac3d3,
        border-primary-dark: #2c3e50,
        border-primary-light: #e1f0f5,
        border-secondary-base: #707070,
        border-secondary-dark: #2c3e50,
        border-secondary-light: #a0a5aa,
    ),
    depth: (
        primary: rgba(223, 223, 223, 0.5),
        secondary: #7ac3d3,
    ),
    button: (
        background-primary-active: #007bff,
        background-primary-hover: #0056b3,
        background-primary-disabled: #66a3ff,
        background-secondary-active: #007bff,
        background-secondary-hover: #0056b3,
        background-secondary-disabled: #66a3ff,
        text-primary-base: #007bff,
        text-primary-dark: #0056b3,
        text-primary-light: #66a3ff,
        text-secondary-base: #6c757d,
        text-secondary-dark: #494f54,
        text-secondary-light: #a0a5aa,
    ),
);

// Typography  // Do we need so many font stacks?
$fonts: (
    base-font-stack: 'SST W20 Light, Verdana, sans-serif',
    roman-font-stack: 'SST W20 Roman, Verdana, sans-serif',
    header-font-stack: 'SST W20 Light, Verdana, sans-serif',
    header-bold-font-stack: 'SST W20 Medium, Verdana, sans-serif',
    quote-font-stack: 'Georgia, Times New Roman, Times, serif',
    cn-font-stack: 'Microsoft YaHei, sans-serif',
    icon-font-stack: 'sonyngp',
    button-font-stack: 'SST W20 Medium, Verdana, sans-serif',
);

$typography: (
    font-size: (
        xs: 0.75rem,
        s: 0.875rem,
        // 16px
            m: 1rem,
        l: 1.25rem,
        xl: 1.5rem,
        subtitle-increased-size: 20px,
    ),
    line-height: (
        xs: 18px,
        s: 24px,
        m: 1rem,
        l: 28px,
        xl: 58px,
    ),
    title-line-height: (
        header-line-height-h1: 42px,
        header-line-height-h2: 38px,
        header-line-height-h3: 34px,
        header-line-height-h4: 30px,
        subtitle-increased-line-height: 30px,
    ),
    font-weight: (
        normal: 400,
        bold: 700,
    ),
    icon-size: (
        xs: 28px,
        s: 42px,
        m: 56px,
        l: 70px,
        xl: 84px,
        xxl: 98px,
    ),
    buttons: (
        line-height-base: 21px,
        line-height-large: 23px,
        size-base: 16px,
        size-large: 18px,
        navigation-close-button-size: 40px,
    ),
);

// Margins and Padding
$spacing: (
    xs: 0.25rem,
    s: 0.5rem,
    m: 1rem,
    l: 1.5rem,
    xl: 2rem,
);

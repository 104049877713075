@import './variables';

/* default */

.btn {
    background-color: transparent;
    border: 1px solid $proGreyLight;
    border-radius: 0;
    box-shadow: none !important;
    color: $proGreyLight;
    font-family: $buttonFontStack;
    font-size: $buttonSize;
    line-height: $buttonLineHeight;
    margin-bottom: 25px;
    margin-right: 16px;
    min-width: 120px;
    padding: 12px 30px;
    text-align: center;
    transition: background-color 0.5s ease, border 0.5s ease;

    &:hover,
    &:focus {
        background-color: $proGreyLight;
        color: $proDarkBlue;
    }

    &:active {
        opacity: 0.8;
    }

    :last-of-type {
        margin-right: 1px;
    }
}

/* wide */

.btn-bold {
    font-weight: bold;
}

.btn-wide {
    padding-left: 45px;
    padding-right: 45px;
}

.btn-extra-lg {
    font-size: 18px;
    padding-bottom: 18px;
    padding-top: 18px;
}

/* yellow */

.btn-yellow {
    background-color: $heroCTAYellow;
    border: 2px solid $heroCTAYellow;
    color: $proDarkBlue;

    &:hover,
    &:focus {
        background-color: $proDarkBlue;
        border: 2px solid $heroCTAYellow;
        color: $heroCTAYellow;
    }
}

/* grey */

.btn-grey {
    background-color: $proGreyLightAlt;
    border: 2px solid $proGreyLightAlt;
    color: $proDarkBlue;

    &:hover,
    &:focus {
        background-color: $proDarkBlue;
        border: 2px solid $proGreyLightAlt;
        color: $proGreyLightAlt;
    }
}

/* blue */

.btn-blue {
    border-color: $proDarkBlue;
    color: $proDarkBlue;

    .active {
        background-color: $headersColor !important;
        color: $white;
    }

    &:hover,
    &:focus {
        background-color: $proDarkBlue;
        color: $white;
    }
}

.btn-blue-invert {
    background-color: $proDarkBlue;
    border-color: $proDarkBlue;
    color: $white;

    &:hover,
    &:focus {
        background-color: transparent;
        color: $proDarkBlue;
    }
}

/* modifiers */

.btn-lg {
    font-size: $buttonSize;
    line-height: $buttonLineHeight;
}

.btn-download {
    background-color: $proDownloadButtonBlue;
    border-color: $proDownloadButtonBlue;
    color: $white;
    margin-bottom: 0;
    max-width: 100%;
    padding: 15px 70px 15px 20px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
        background-color: $proDownloadButtonBlueHover;
        border-color: $proDownloadButtonBlueHover;
        color: $white;
    }

    .download-icon {
        display: block;
        font-size: $h3Size;
        height: $h3Size;
        position: absolute;
        right: 22px;
        top: 50%;
        width: $h3Size;
        transform: translateY(-50%);
    }

    &.btn-download-yellow {
        background-color: $heroCTAYellow;
        border-color: $heroCTAYellow;
        color: $proDarkBlue;

        &:hover,
        &:focus,
        &:active {
            background-color: $proDarkBlue;
            color: $heroCTAYellow;
        }
    }
}

/* custom buttons */

.btn-expand-collapse {
    background-color: $proLilac;
    border: 0;
    color: $proDarkBlue;
    display: inline-block;
    font-family: $headerBoldFontStack;
    font-size: $bodySize;
    line-height: $bodySize;
    padding: 14px 60px 14px 25px;
    position: relative;

    &::after {
        color: $proDarkBlue;
        content: '\7a';
        font-family: $iconFontStack;
        font-size: $h1Size;
        height: 14px;
        line-height: calc($h1Size / 4);
        margin-top: -8px;
        position: absolute;
        right: 15px;
        text-align: center;
        text-indent: -5px;
        top: 50%;
        transition: background-color 0.25s ease;
        width: 23px;
    }

    &:hover,
    &:focus {
        background-color: $proLilacHover;
    }

    &:focus-visible {
        outline: revert;
    }
}

.lang-zh {
    .btn {
        font-family: $buttonFontStackCN;
    }

    .btn-expand-collapse {
        font-family: $headerBoldFontStackCN;
    }
}

.btn-collapse-all {
    &::after {
        content: '\77';
        line-height: 16px;
        text-indent: -4px;
    }
}

.btn-lilac {
    background-color: $proLilac;
    color: $proDarkBlue;

    &:hover,
    &:focus {
        background-color: $proLilacHover;
    }
}

.btn-white {
    background-color: $white;
    color: $proDarkBlue;

    &:hover,
    &:focus {
        background-color: $proLilacHover;
    }
}

.btn-solutions-light {
    border: 1px solid $solBlue;
    color: $solBlue;

    &:hover,
    &:focus {
        background-color: $solBlue;
        border: 1px solid $solBlue;
        color: $white;
    }
}

.btn-solutions-dark {
    background-color: none;
    border: 1px solid $solutionsDarkButtonBlue;
    color: $white;

    &:hover,
    &:focus {
        background-color: $solutionsDarkButtonBlue;
        border: 1px solid $solutionsDarkButtonBlue;
        color: $white;
    }
}

.btn-solutions-filter {
    background-color: transparent;
    border: 1px solid $solBlue;
    color: $solBlue;
    padding: 8px 15px;

    &:hover {
        background-color: $solBlue;
        border: 1px solid $solBlue;
        color: $white;
    }
    &:focus {
        background-color: transparent;
        border: 1px solid $solBlue;
        color: $solBlue;
    }

    &.active-filter {
        background-color: $solBlue !important;
        border: 1px solid $solBlue !important;
        color: $white;
        &:focus {
            background-color: $solBlue !important;
            border: 1px solid $solBlue !important;
            color: $white !important;
        }
    }
}

.btn-disabled {
    cursor: not-allowed;
    font-size: $bodyReducedSize;
    line-height: $buttonSizeBig;
}

.btn-flat {
    border: 0;
}

.scrollToTop {
    position: fixed;
    width: 40px !important;
    height: 40px !important;
    bottom: 40px;
    right: 40px;
    background-color: $white;
    color: $solBlue;
    text-align: center;
    padding: 0px !important;
    margin: 0px;
    font-size: 26px;
    border: 1px solid $solBlue;
    border-radius: 3px;
    opacity: 0.6;
    z-index: 999;
}
.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s linear;
}

.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: all 0.25s linear;

    &:hover,
    &:focus {
        transition: none;
        background-color: $white;
        border: 2px solid $solBlue;
        opacity: 1;
        i {
            font-weight: bolder;
        }
    }
}

@media only screen and (max-width: 480px) {
    .btn {
        &,
        &:last-of-type {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }
}

@import '../../../sass/buttons';

.login-register {
    background-color: #35495e;
    color: #fff;
    display: block;
    opacity: 0;
    padding: 30px 20px;
    position: absolute;
    right: 0;
    top: calc(100% + 16px);
    transition: all 0.5s ease;
    visibility: hidden;
    width: 100%;
    z-index: 10;

    @media (min-width: 768px) {
        width: 60%;
        top: calc(100%);
    }

    &.active {
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease;
    }

    .btn {
        font-size: $buttonSize;
        line-height: $buttonLineHeightBig;
    }
}

.navigation-hamburger {
    position: relative;
    transition: all ease-in-out 0.4s;
    width: 22px;
    height: 22px;
    overflow: hidden;

    .line {
        position: relative;
        background-color: #fff;
        opacity: 1;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        transition: all ease-in-out 0.4s;
    }

    &.is-closed {
        .line {
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    &.is-open {

        .line {
            &:first-child {
                transform: rotate(-45deg) translate(-3px, -2px);
            }

            &:nth-of-type(2) {
                opacity: 0;
            }

            &:last-child {
                transform: rotate(45deg) translate(-5px, 0px);
            }
        }
    }
}

@import '../../../sass/variables';
@import '../../../sass/animation';

#desktop-navigation {
    padding-left: 4.125rem;
    padding-right: 4.125rem;
}

#navigation {
    position: relative;
    font-size: 16px;
    .navigation-container {
        position: relative;
        z-index: 100;
    }

    .navigation-top-level-item {
        & > button {
            font-weight: 400;
            border-color: rgba(0, 0, 0, 0);
            border-bottom-width: 6px;
            margin-bottom: -6px;
            margin-right: 1.4rem;
        }
        & > button:hover,
        & > button:focus {
            border-color: rgba(129, 220, 247, 1);
        }

        .navigation-drop {
            position: absolute;
            top: 4rem;
            z-index: 10;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;

            img {
                margin-bottom: 1rem;
            }

            a {
                color: #fff;
                &.parent {
                    color: #81dcf7;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &.navigation-open {
            & > .navigation-drop {
                visibility: visible;
                opacity: 1;
                transition: opacity 0s ease;
            }
        }

        &.navigation-hidden {
            & > .navigation-drop {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s 0s, opacity 0.4s ease;
            }
        }
    }

    .navigation-bar {
        ul {
            overflow: hidden;
            li {
                &::after {
                    opacity: 100;
                    animation: fadeLineIn 0.2s backwards ease-in-out;
                }

                button {
                    height: 4rem;
                    position: relative;
                }
            }
        }
    }
}

.dropdown-bg {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: rgba(0, 0, 0, 0.45);
    z-index: 9;
    margin-bottom: 20rem;
}

@mixin loginTooltip($position) {
    &::after {
        border-color: transparent transparent $lightBlue;
        border-style: solid;
        border-width: 0 10px 10px;
        bottom: 0;
        content: '';
        display: block;
        height: 0;
        bottom: 100%;
        left: $position;
        margin-left: -10px;
        opacity: 1;
        position: absolute;

        /* FIXME: fix 'all' */
        transition: all 0.5s ease;
        visibility: visible;
        width: 0;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    .ngp-icon {
        &::after {
            height: 28px;
            width: 28px;
        }
    }

    & > a {
        &:first-of-type,
        &:last-of-type {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.navbar-right,
.login-register-tooltip {
    @include loginTooltip(90%);
}

/* search tool tip */
.search-tooltip {
    background-color: $white;
    color: $proTextGray;
    display: block;
    max-width: 100%;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 100%;
    @include loginTooltip(95%);

    /* FIXME: fix 'all' */
    transition: all 0.5s ease;
    visibility: hidden;
    width: 540px;
    z-index: 99;

    &.active {
        opacity: 1;
        visibility: visible;
    }
    .typeahead-wrapper {
        background-color: $white;

        .typeahead-default {
            background-color: $proDarkBlue;
            border: 1px solid $lightBlue;
            color: $proBlueLight;
            padding: 9px 20px;

            input {
                background-color: transparent;
                border: 0;
                color: $proBlueLight;
                width: 100%;

                &::-webkit-input-placeholder {
                    color: $white !important;
                    opacity: 0.8;
                }

                &:-moz-placeholder {
                    color: $white !important;
                    opacity: 0.8;
                }

                &::-moz-placeholder {
                    color: $white !important;
                    opacity: 0.8;
                }

                &:-ms-input-placeholder {
                    color: $white !important;
                    opacity: 0.8;
                }
            }
        }

        .typeahead-results {
            color: $proTextGray;
            display: none;
            padding: 0;
        }

        .has-results {
            display: block;
            font-size: 13px;

            ul {
                & > li {
                    text-align: left;
                    border-bottom: 1px solid $proGreyLight;
                    overflow: hidden;
                    padding: 15px 15px 15px 18px;
                    position: relative;

                    &::after {
                        clear: both;
                        content: '';
                        display: table;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        background-color: $proGreyLight;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                    }

                    h5 {
                        font-size: 14px;
                        font-weight: normal;
                        margin-bottom: 0;
                        max-width: 250px;
                    }

                    p {
                        line-height: 18px;
                        font-size: 16px;
                        margin-bottom: 0;
                        max-width: 250px;
                    }

                    img {
                        display: none;
                        max-height: 80px;
                        max-width: 110px;
                        position: absolute;
                        right: 25px;
                        top: 10px;

                        &[src] {
                            display: block;
                        }
                    }

                    .block-link {
                        display: block;
                        height: 100%;
                        left: 0;
                        position: absolute;
                        text-indent: -9999px;
                        top: 0;
                        width: 100%;
                    }
                }
            }

            .show-all-results {
                display: block;
                font-weight: bold;
                margin-bottom: 15px;
                padding: 0 18px;
            }
        }
    }
}

@keyframes fadeLineIn {
    from {
        opacity: 0;
        width: 50%;
        transform: translateY(5px);
    }

    to {
        opacity: 100;
        width: 100%;
        transform: translateY(0px);
    }
}

.animate-in {
    animation: mobilenavin forwards 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.1);
}

.animate-out {
    animation: mobilenavout forwards 0.2s ease-out;
}

#mobile-navigation {
    ul.sub-menu-open {
        transform: translateX(-100vw);

        & > ul {
            transform: translateX(0);
        }
    }

    & > ul {
        & > ul {
            transform: translateX(100vw);
        }
    }
}

@keyframes mobilenavin {
    0% {
        opacity: 0;
        transform: translateX(100vw);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes mobilenavout {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-100vw);
    }
}

@media only screen and (max-width: 1024px) {
    .navbar-right,
    .login-register-tooltip {
        @include loginTooltip(88%);
        text-align: center;
    }

    .navbar-right,
    .search-tooltip {
        @include loginTooltip(95%);
    }
}

@media only screen and (max-width: 768px) {
    .navbar-right,
    .login-register-tooltip {
        &::after {
            display: none;
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .search-tooltip {
        margin-top: 20px;
    }

    .login-register {
        text-align: center;
    }
}
